/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ConnectedRouter } from 'connected-react-router';

import PrivateRoute from './components/PrivateRoute';
import Toasts from './modules/notification/Toasts';
import { history } from './core/store';

import './scss/style.scss';

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse" />
	</div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Page404 = React.lazy(() => import('./modules/_global/screens/Page404'));
const Page500 = React.lazy(() => import('./modules/_global/screens/Page500'));
const Login = React.lazy(() => import('./modules/auth/screens/LoginScreen'));
const Register = React.lazy(() =>
	import('./modules/auth/screens/RegisterScreen'),
);

class App extends Component {
	render() {
		return (
			<ConnectedRouter history={history}>
				<React.Suspense fallback={loading}>
					<Toasts />
					<Switch>
						<Route
							exact
							name="Login Page"
							path="/login"
							render={(props) => <Login {...props} />}
						/>
						<Route
							exact
							name="Register Page"
							path="/register"
							render={(props) => <Register {...props} />}
						/>
						<Route
							exact
							name="Page 404"
							path="/404"
							render={(props) => <Page404 {...props} />}
						/>
						<Route
							exact
							name="Page 500"
							path="/500"
							render={(props) => <Page500 {...props} />}
						/>
						<PrivateRoute
							component={TheLayout}
							name="Home"
							path="/"
						/>
					</Switch>
				</React.Suspense>
			</ConnectedRouter>
		);
	}
}

export default App;

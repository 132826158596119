import { get } from 'lodash';

import {
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	RETRIEVE_USER_DETAILS_SUCCESS,
	RETRIEVE_USER_PERMISSIONS_SUCCESS,
} from './auth.actionTypes';

const initialState = {
	jwt: null,
	permissions: {},
	user: {},
};

export default (state = initialState, action = null) => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				jwt: get(action, 'data.jwt'),
			};

		case LOGOUT_SUCCESS:
			return {
				...initialState,
			};

		case RETRIEVE_USER_DETAILS_SUCCESS:
			return {
				...state,
				user: get(action, 'data', {}),
			};

		case RETRIEVE_USER_PERMISSIONS_SUCCESS:
			return {
				...state,
				permissions: get(action, 'data', {}),
			};

		default:
			return state;
	}
};

import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import auth from '../modules/auth/engine/auth.reducer';
import layout from '../containers/engine/layout.reducer';
import toasts from '../modules/notification/engine/toasts.reducer';

const createRootReducer = (history) =>
	combineReducers({
		auth,
		layout,
		router: connectRouter(history),
		toasts,
	});

export default createRootReducer;

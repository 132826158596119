/* eslint-disable import/prefer-default-export */
import {
	cibSkype,
	cibFacebook,
	cibTwitter,
	cibLinkedin,
	cibFlickr,
	cibTumblr,
	cibXing,
	cibGithub,
	cibStackoverflow,
	cibYoutube,
	cibDribbble,
	cibInstagram,
	cibPinterest,
	cibVk,
	cibYahoo,
	cibBehance,
	cibReddit,
	cibVimeo,
	cibCcMastercard,
	cibCcVisa,
	cibStripe,
	cibPaypal,
	cibGooglePay,
	cibCcAmex,
	cifUs,
	cifBr,
	cifIn,
	cifFr,
	cifEs,
	cifPl,
	cilAlignCenter,
	cilAlignLeft,
	cilAlignRight,
	cilApplicationsSettings,
	cilArrowRight,
	cilArrowTop,
	cilAsterisk,
	cilBan,
	cilBasket,
	cilBell,
	cilBold,
	cilBookmark,
	cilCalculator,
	cilCalendar,
	cilCloudDownload,
	cilChartPie,
	cilCheck,
	cilChevronBottom,
	cilChevronLeft,
	cilChevronRight,
	cilChevronTop,
	cilCircle,
	cilCheckCircle,
	cilCode,
	cilCommentSquare,
	cilCreditCard,
	cilCursor,
	cilCursorMove,
	cilDrop,
	cilDollar,
	cilEnvelopeClosed,
	cilEnvelopeLetter,
	cilEnvelopeOpen,
	cilEuro,
	cilGlobeAlt,
	cilGrid,
	cilFile,
	cilFullscreen,
	cilFullscreenExit,
	cilGraph,
	cilHome,
	cilInbox,
	cilIndentDecrease,
	cilIndentIncrease,
	cilInputPower,
	cilItalic,
	cilJustifyCenter,
	cilJustifyLeft,
	cilLaptop,
	cilLayers,
	cilLightbulb,
	cilList,
	cilListNumbered,
	cilListRich,
	cilLocationPin,
	cilLockLocked,
	cilLockUnlocked,
	cilMagnifyingGlass,
	cilMap,
	cilMoon,
	cilNotes,
	cilOptions,
	cilPaperclip,
	cilPaperPlane,
	cilPencil,
	cilPeople,
	cilPhone,
	cilPlus,
	cilPrint,
	cilPuzzle,
	cilSave,
	cilScrubber,
	cilSettings,
	cilShare,
	cilShareAll,
	cilShareBoxed,
	cilShieldAlt,
	cilSpeech,
	cilSpeedometer,
	cilSpreadsheet,
	cilStar,
	cilSun,
	cilTags,
	cilTask,
	cilTrash,
	cilUnderline,
	cilUser,
	cilUserFemale,
	cilUserFollow,
	cilUserUnfollow,
	cilX,
	cilXCircle,
	cilWarning,
} from '@coreui/icons';
import { sygnet } from './sygnet';
import { logo } from './logo';
import { logoNegative } from './logo-negative';

export const icons = {
	sygnet,
	logo,
	logoNegative,
	cilAlignCenter,
	cilAlignLeft,
	cilAlignRight,
	cilApplicationsSettings,
	cilArrowRight,
	cilArrowTop,
	cilAsterisk,
	cilBan,
	cilBasket,
	cilBell,
	cilBold,
	cilBookmark,
	cilCalculator,
	cilCalendar,
	cilCloudDownload,
	cilChartPie,
	cilCheck,
	cilChevronBottom,
	cilChevronLeft,
	cilChevronRight,
	cilChevronTop,
	cilCircle,
	cilCheckCircle,
	cilCode,
	cilCommentSquare,
	cilCreditCard,
	cilCursor,
	cilCursorMove,
	cilDrop,
	cilDollar,
	cilEnvelopeClosed,
	cilEnvelopeLetter,
	cilEnvelopeOpen,
	cilEuro,
	cilGlobeAlt,
	cilGrid,
	cilFile,
	cilFullscreen,
	cilFullscreenExit,
	cilGraph,
	cilHome,
	cilInbox,
	cilIndentDecrease,
	cilIndentIncrease,
	cilInputPower,
	cilItalic,
	cilJustifyCenter,
	cilJustifyLeft,
	cilLaptop,
	cilLayers,
	cilLightbulb,
	cilList,
	cilListNumbered,
	cilListRich,
	cilLocationPin,
	cilLockLocked,
	cilLockUnlocked,
	cilMagnifyingGlass,
	cilMap,
	cilMoon,
	cilNotes,
	cilOptions,
	cilPaperclip,
	cilPaperPlane,
	cilPencil,
	cilPeople,
	cilPhone,
	cilPlus,
	cilPrint,
	cilPuzzle,
	cilSave,
	cilScrubber,
	cilSettings,
	cilShare,
	cilShareAll,
	cilShareBoxed,
	cilShieldAlt,
	cilSpeech,
	cilSpeedometer,
	cilSpreadsheet,
	cilStar,
	cilSun,
	cilTags,
	cilTask,
	cilTrash,
	cilUnderline,
	cilUser,
	cilUserFemale,
	cilUserFollow,
	cilUserUnfollow,
	cilX,
	cilXCircle,
	cilWarning,
	cifUs,
	cifBr,
	cifIn,
	cifFr,
	cifEs,
	cifPl,
	cibSkype,
	cibFacebook,
	cibTwitter,
	cibLinkedin,
	cibFlickr,
	cibTumblr,
	cibXing,
	cibGithub,
	cibStackoverflow,
	cibYoutube,
	cibDribbble,
	cibInstagram,
	cibPinterest,
	cibVk,
	cibYahoo,
	cibBehance,
	cibReddit,
	cibVimeo,
	cibCcMastercard,
	cibCcVisa,
	cibStripe,
	cibPaypal,
	cibGooglePay,
	cibCcAmex,
};

import api from '../../../utils/api';
import { ENDPOINTS } from '../../../constants/api';

export const retrieveUserDetails = () => {
	return api.get(`${ENDPOINTS.USERS}/me`);
};

export const retrieveUserPermissions = () => {
	return api.get(`${ENDPOINTS.PERMISSIONS}/me`);
};

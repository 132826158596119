import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CToaster, CToast, CToastHeader, CToastBody } from '@coreui/react';

import { TOAST_TYPE_CLASSES } from '../../constants/notification';

import { removeToast } from './engine/toasts.actions';
import { toastsSelector } from './engine/toasts.selectors';

const Toasts = () => {
	const dispatch = useDispatch();
	const toasts = useSelector(toastsSelector);
	const [shownToasts, setShownToasts] = useState({});

	useEffect(() => {
		const shownToastKeys = Object.keys(shownToasts);

		if (shownToastKeys.length) {
			const hasShown = shownToastKeys.reduce(
				(_hasShown, shownToastKey) => {
					if (_hasShown) return _hasShown;
					if (shownToasts[shownToastKey]) return true;
					return false;
				},
				false,
			);

			if (!hasShown) {
				shownToastKeys.forEach((shownToastKey) => {
					dispatch(removeToast(shownToastKey));
				});

				setShownToasts([]);
			}
		}
	}, [shownToasts]);

	return (
		<CToaster position="top-right">
			{toasts.map(({ autohide = 5000, key, message, title, type }) => (
				<CToast
					key={`toast-${key}`}
					autohide={autohide}
					fade
					onStateChange={(e) => {
						setShownToasts({
							...shownToasts,
							[key]: e,
						});
					}}
					show
				>
					<CToastHeader
						className={TOAST_TYPE_CLASSES[type]}
						closeButton
					>
						{title}
					</CToastHeader>
					<CToastBody>{message}</CToastBody>
				</CToast>
			))}
		</CToaster>
	);
};

export default Toasts;

import axios from 'axios';
import qs from 'qs';

import { CONFIG } from '../constants/api';

const { timeout } = CONFIG;

const instance = axios.create({
	baseURL: !process.env.CI ? process.env.REACT_APP_API_URL : undefined,
	timeout,
});

instance.defaults.paramsSerializer = (params) => qs.stringify(params);
instance.interceptors.response.use(({ data }) => data);

export const attachToken = (token) => {
	instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const detachToken = () => {
	delete instance.defaults.headers.common.Authorization;
};

export default instance;

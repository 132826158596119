import { nanoid } from 'nanoid';
import { ADD_TOAST, REMOVE_TOAST } from './toasts.actionTypes';

/**
 * Toast structure
 * {
 * 		key: unique-key,
 * 		title: 'Title',
 * 		message: 'Some message'
 * }
 */
const initialState = [];

const toastsReducer = (state = initialState, { data, type }) => {
	switch (type) {
		case ADD_TOAST:
			return [...state, { ...data, key: nanoid() }];

		case REMOVE_TOAST:
			return state.filter(({ key }) => key !== data);

		default:
			return state;
	}
};

export default toastsReducer;

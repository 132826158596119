/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

import {
	setUserDetails,
	setUserPermissions,
} from '../modules/auth/engine/auth.actions';
import {
	retrieveUserDetails,
	retrieveUserPermissions,
} from '../modules/auth/engine/auth.queries';
import { tokenSelector } from '../modules/auth/engine/auth.selectors';
import { defaultErrorHandler } from '../modules/notification/engine/notification.library';
import { attachToken } from '../utils/api';

const PrivateRoute = ({ component: Component, ...rest }) => {
	const dispatch = useDispatch();

	const authToken = useSelector(tokenSelector);

	useEffect(() => {
		if (authToken) {
			attachToken(authToken);

			Promise.all([retrieveUserDetails(), retrieveUserPermissions()])
				.then(([details, permissions]) => {
					dispatch(setUserDetails(details));
					dispatch(setUserPermissions(permissions));
				})
				.catch(defaultErrorHandler.bind(null, dispatch));
		}
	}, [authToken]);

	return (
		<Route
			{...rest}
			render={(props) => {
				return authToken ? (
					<Component {...props} />
				) : (
					<Redirect to="/login" />
				);
			}}
		/>
	);
};

PrivateRoute.propTypes = {
	component: PropTypes.object.isRequired,
};

export default PrivateRoute;

export const TOAST_TYPES = {
	SUCCESS: 'success',
	FAIL: 'fail',
};

export const TOAST_TYPE_CLASSES = {
	success: 'bg-success text-white',
	fail: 'bg-danger text-white',
};

export const titleOverrides = {
	'Bad Request': 'Error',
};

export const messageOverrides = {
	'Auth.form.error.invalid': 'Invalid email address or password',
	Forbidden:
		'You do not have permission to access this resource. Please contact our tech support team to request for access.',
	'already.confirmed': 'This email is already confirmed',
};

export const DEFAULT_SUCCESS_TITLE = 'Success';

export const DEFAULT_ERROR_TITLE = 'Something Went Wrong';
export const DEFAULT_ERROR_MESSAGE =
	'An unknown error has occured. Please contact our tech support team.';

export const DEFAULT_CREATE_SUCCESS = 'Record created successfully';
export const DEFAULT_DELETE_SUCCESS = 'Record deleted successfully';
export const DEFAULT_UPDATE_SUCCESS = 'Record updated successfully';

import { detachToken } from '../../../utils/api';

import {
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	RETRIEVE_USER_DETAILS_SUCCESS,
	RETRIEVE_USER_PERMISSIONS_SUCCESS,
} from './auth.actionTypes';

export const login = (data) => {
	return {
		type: LOGIN_SUCCESS,
		data,
	};
};

export const logout = () => {
	detachToken();

	return {
		type: LOGOUT_SUCCESS,
	};
};

export const setUserDetails = (data) => {
	return {
		type: RETRIEVE_USER_DETAILS_SUCCESS,
		data,
	};
};

export const setUserPermissions = (data) => {
	return {
		type: RETRIEVE_USER_PERMISSIONS_SUCCESS,
		data,
	};
};

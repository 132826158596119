import { TOAST_TYPES } from '../../../constants/notification';

import { ADD_TOAST, REMOVE_TOAST } from './toasts.actionTypes';

export const removeToast = (key) => ({
	type: REMOVE_TOAST,
	data: key,
});

export const addToast = ({ title, message, type = TOAST_TYPES.SUCCESS }) => ({
	type: ADD_TOAST,
	data: {
		title,
		message,
		type,
	},
});

export const addSuccessToast = ({
	title = 'Success',
	message,
	type = TOAST_TYPES.SUCCESS,
}) => addToast({ title, message, type });

export const addFailedToast = ({
	title = 'Failed',
	message,
	type = TOAST_TYPES.FAIL,
}) => addToast({ title, message, type });

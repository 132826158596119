import { TOGGLE_SIDE_BAR } from './layout.actionTypes';

const initialState = {
	showSidebar: 'responsive',
};

const layoutReducer = (state = initialState, { data, type }) => {
	switch (type) {
		case TOGGLE_SIDE_BAR:
			return {
				...state,
				showSidebar: data,
			};
		default:
			return state;
	}
};

export default layoutReducer;

export const CONFIG = {
	timeout: 300000,
};

export const ENDPOINTS = {
	ABOUT_US: '/api/about-us',
	AUTH: '/api/auth',
	BROADCASTS: '/api/broadcasts',
	CATEGORIES: '/api/categories',
	INSTITUTIONS: '/api/institutions',
	HOMEPAGE_SETTINGS: '/api/homepage-settings',
	MEDIA_ITEMS: '/api/media-items',
	NEWSLETTER_SUBSCRIBERS: '/api/newsletter-subscribers',
	NEWSLETTER: '/api/newsletters',
	PAYMENTS: '/api/payments',
	PERMISSIONS: 'api/users-permissions',
	PROMO_CODES: '/api/promo-codes',
	SUBMISSIONS: '/api/submissions',
	TAGS: '/api/tags',
	UPLOAD: '/api/upload',
	USERS: '/api/users',
};

import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createRootReducer from './reducers';

export const history = createBrowserHistory();

let middlewares;
if (process.env.NODE_ENV !== 'production') {
	middlewares = [routerMiddleware(history), logger];
} else {
	middlewares = [routerMiddleware(history)];
}

const rootReducer = createRootReducer(history);

const configureStore = (preloadedState) => {
	const persistConfig = {
		key: 'auth',
		storage,
		whitelist: ['auth'],
	};

	const persistedReducer = persistReducer(persistConfig, rootReducer);

	const store = createStore(
		persistedReducer,
		preloadedState,
		compose(applyMiddleware(...middlewares)),
	);

	const persistor = persistStore(store);

	return { store, persistor };
};

export default configureStore;
